
module.exports = {
  brand: function(d) { return "THE OUTNET"; },
  contact: {
    title: function(d) { return "Need help?"; },
    copyBeforeLink: function(d) { return "For any enquiries please visit THE OUTNET"; },
    copyAfterLink: function(d) { return "."; },
    href: function(d) { return "/page/help/returns/"; },
    customerCare: function(d) { return "Customer Care"; }
  },
  search: {
    text: function(d) { return "Search THE OUTNET"; },
    clear: function(d) { return "Clear"; },
    categories: function(d) { return "Categories"; },
    designers: function(d) { return "Designers"; },
    products: function(d) { return "Products"; },
    colours: function(d) { return "Colours"; },
    cta: function(d) { return "Search"; },
    noResult: function(d) { return "We have found no result for " + d.searchTerm; },
    tryAgain: function(d) { return "Please try again."; },
    loading: function(d) { return "Searching"; },
    viewAll: function(d) { return "View All Results (" + d.count + ")"; }
  },
  price: {
    discountPercentage: function(d) { return "(-" + d.discountPercentage + "%)"; },
    discountPrice: function(d) { return "discount price"; },
    currencyExchange: function(d) { return "(Approx " + d.exchangeCurrency + " " + d.exchangePrice + ")"; },
    wasPriceOriginal: function(d) { return "RRP: " + d.wasPrice; },
    wasPrice: function(d) { return "RRP:"; },
    originalPrice: function(d) { return "Original price:"; },
    minimumPrice: function(d) { return "Lowest price in 30 days:"; },
    minimumPriceEqual: function(d) { return "This is the lowest price in 30 days"; },
    priceInformation: {
      title: function(d) { return "Pricing Information"; },
      subtitle: function(d) { return "Get familiar with THE OUTNET price display:"; },
      descriptionRRP: function(d) { return "**1. Current price:**\nThe price at which the product is offered exclusively on THE OUTNET app and website\n\n**2. Lowest price in 30 days:**\nThe lowest price at which the product was on sale in the last 30 days\n\n**3. RRP:**\nThe recommended retail price by the manufacturer\n\n[Find Out More](" + d.link + ")"; },
      descriptionOriginalPrice: function(d) { return "**1. Current price:**\nThe price at which the product is offered exclusively on THE OUTNET app and website\n\n**2. Lowest price in 30 days:**\nThe lowest price at which the product was on sale in the last 30 days\n\n**3. Original price:**\nThe price the item was first listed at on THE OUTNET app and website\n\n[Find Out More](" + d.link + ")"; },
      descriptionRRPWithoutMinimumMessage: function(d) { return "**1. Current price:**\nThe price at which the product is offered exclusively on THE OUTNET app and website\n\n**2. RRP:**\nThe recommended retail price by the manufacturer\n\n[Find Out More](" + d.link + ")"; },
      descriptionOriginalPriceWithoutMinimumMessage: function(d) { return "**1. Current price:**\nThe price at which the product is offered exclusively on THE OUTNET app and website\n\n**2. Original price:**\nThe price the item was first listed at on THE OUTNET app and website\n\n[Find Out More](" + d.link + ")"; },
      descriptionThirdPartyUs: function(d) { return "**1. Current price:**\nThis is the price at which the product is currently offered on THE OUTNET app and website. The Current Price is influenced by various factors, including our cost, inventory levels, competitive market trends, and customer demand. This price is unique to our online store and may fluctuate based on promotional activities and stock availability\n\n**2. Reference price:**\nThe reference price is a price provided to us by the manufacturer or the retailer that supplied us with the product. This represents a suggested retail price for the product. It is not an indication of the price at which the product is sold by other retailers or outlets, which may vary. We encourage you to use the Reference Price as a guide in assessing the value of our Current Price.\n\nAt THE OUTNET we continuously monitor market dynamics to ensure our prices are competitive and reflective of the current market trends. Our aim is to provide quality products at prices that are fair and transparent, ensuring that you always receive the best value for your money.\n\n[Find Out More](" + d.link + ")"; }
    }
  },
  localeSwitch: {
    languages: {
      en: function(d) { return "English"; },
      ar: function(d) { return "اللغة العربية"; },
      de: function(d) { return "Deutsch"; },
      fr: function(d) { return "française"; },
      ja: function(d) { return "日本語"; },
      ko: function(d) { return "한국어"; }
    },
    changeCountry: {
      title: function(d) { return "Change Location"; },
      description: function(d) { return "To get the best of THE OUTNET, make sure you select your location from the list below"; }
    },
    itemsUnavailable: {
      title: function(d) { return "Items Unavailable"; },
      description: function(d) { return "Unfortunately one or more items in your Bag are currently unavailable in this Country. We will need to remove these items from your bag if you change country."; },
      confirmation: function(d) { return "Confirm Change Country"; },
      addToWishlist: function(d) { return "Add items to Wish List"; }
    }
  },
  cookieBanner: {
    title: function(d) { return "Cookies and privacy"; },
    message: function(d) { return "By continuing to use our website, you consent to our use of cookies to ensure the functioning of the website, to personalize the displayed content, to conduct statistical and analytical research in order to improve products and services we offer. You can block or delete cookies in your browser settings. For more information about the use of cookies please consult section"; },
    cta: function(d) { return "\"Cookie Policy\"."; },
    href: function(d) { return "/page/help/cookie-policy"; }
  },
  nav: {
    myAccount: {
      text: function(d) { return "Hi, " + d.name; },
      href: function(d) { return "/account"; }
    },
    myAccountMobile: {
      text: function(d) { return "Your Account"; }
    },
    signIn: {
      text: function(d) { return "Sign In"; },
      href: function(d) { return "/account/login"; }
    },
    signOut: {
      text: function(d) { return "Sign Out"; },
      href: function(d) { return "/account/login"; }
    },
    account: {
      text: function(d) { return "Your Account"; },
      href: function(d) { return "/account"; }
    },
    wishlist: {
      text: function(d) { return "Wish List"; },
      href: function(d) { return "/account/wishlist"; }
    },
    checkout: {
      text: function(d) { return "Checkout"; },
      href: function(d) { return "/checkout"; }
    },
    register: {
      text: function(d) { return "Register"; },
      href: function(d) { return "/account/login"; }
    }
  },
  courtesyNav: {
    customerServiceInfo: {
      text: function(d) { return "Customer Care"; },
      href: function(d) { return "/page/help/contact-us"; }
    },
    deliveryInfo: {
      text: function(d) { return "Delivery"; },
      href: function(d) { return "/page/help/customer-service/delivery"; }
    },
    returnsInfo: {
      text: function(d) { return "Returns"; },
      href: function(d) { return "/page/help/customer-service/returns"; }
    },
    myOrders: {
      text: function(d) { return "Your Orders"; },
      href: function(d) { return "/account/orders"; }
    },
    trackOrder: {
      text: function(d) { return "Track Your Order"; },
      href: function(d) { return "/account/track-order"; }
    },
    createReturn: {
      text: function(d) { return "Create A Return"; },
      href: function(d) { return "/account/create-return"; }
    }
  },
  copyright: {
    yearFrom: function(d) { return "2009 - "; },
    copy: function(d) { return ", part of YOOX NET-A-PORTER GROUP."; },
    extraCopy: function(d) { return "The individuals featured on this site do not endorse THE OUTNET or the products shown."; }
  },
  social: {
    title: function(d) { return "Stay Social"; }
  },
  externalLinks: {
    mrp: function(d) { return "https://www.mrporter.com/"; },
    nap: function(d) { return "https://www.net-a-porter.com/"; }
  },
  error: {
    megaNav: function(d) { return "Woops, sorry that menu couldn't be found"; }
  },
  checkout: {
    copy: function(d) { return "Secure Checkout"; }
  },
  footer: {
    footerLinks: function(d) { return "Help & Information"; },
    contactAndLocale: function(d) { return "24/7 Customer Care"; },
    signupAndSocial: function(d) { return "Stay Social"; }
  },
  fastUserRegistration: {
    heading: function(d) { return "Want to stay stylishly in the know?"; },
    description: function(d) { return "Subscribe to THE OUTNET to receive an extra 15% off your next order! You'll also be the first to know about our exclusive new arrivals, latest trends, and upcoming promotions."; },
    subscribe: function(d) { return "Subscribe"; },
    placeholder: function(d) { return "Enter your Email Address"; },
    sucessfulEmailMessage: function(d) { return "Thanks, you’re now signed up to receive our emails!"; },
    NoEmailMessage: function(d) { return "Enter your email address"; },
    invalidEmailMessage: function(d) { return "Invalid email address"; }
  },
  affirmUSFooterInfo: {
    heading: function(d) { return "Affirm Payment"; },
    descriptionBeforeLink: function(d) { return "Rates from 0–36% APR. For example, a $800 purchase might cost $72.21/mo over 12 months at 15% APR. Payment options through Affirm are subject to an eligibility check, may not be available everywhere, and are provided by these lending partners: "; },
    descriptionMidLink: function(d) { return ". Options depend on your purchase amount, and a down payment may be required. Estimated payment amount may exclude taxes and shipping. CA residents: Loans by Affirm Loan Services, LLC are made or arranged pursuant to a California Financing Law license. For licenses and disclosures, see Sample "; },
    affirmLinkLender: function(d) { return "https://affirm.com/lenders"; },
    affirmLinkLicenses: function(d) { return "https://affirm.com/licenses"; },
    affirmLinkLicensesCopy: function(d) { return "affirm.com/licenses"; },
    affirmLinkLenderCopy: function(d) { return "affirm.com/lenders"; },
    descriptionAfterLink: function(d) { return "."; },
    linkText: function(d) { return "Learn more"; },
    href: function(d) { return "/page/help/affirm/"; }
  },
  complianceHKFooterInfo: {
    heading: function(d) { return "DPMS Category A Registrant (Registration No. A-B-23-12-03190)"; }
  },
  seoMeta: {
    title: function(d) { return "Discount Designer Fashion | Sale Up To 70% Off At THE OUTNET"; },
    description: function(d) { return "With 350+ luxury designer fashion brands at exceptional prices, shopping at THE OUTNET is guaranteed to take your style to the next level!"; }
  },
  webpush: {
    title: function(d) { return "Want to be the first to hear about our latest promotions and discounts?"; },
    description: function(d) { return "We’ll send you a notification so you don’t miss out!"; },
    toggleMessage: function(d) { return "Enable Web Push"; }
  },
  multishop: {
    mens: function(d) { return "Men"; },
    womens: function(d) { return "Women"; }
  },
  promptBasketItem: {
    title: function(d) { return "This item has now been added to your bag"; },
    button: function(d) { return "Continue to bag"; }
  },
  recentlyViewed: {
    title: function(d) { return "Are you still interested in?"; }
  }
}